import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getShopifyImage } from "gatsby-source-shopify";
import debounce from "lodash.debounce";
import { StoreContext } from "../../../context/storeContext";
import { formatPrice } from "../../../utils/format-price";

export default function LineItem({ item }) {
  const { removeLineItem, checkout, updateLineItem, loading } =
    React.useContext(StoreContext);

  const [quantity, setQuantity] = React.useState(item.quantity);

  const variantImage = {
    src: item.merchandise.image.url,
    originalSrc: item.merchandise.image.url,
  };

  const price = formatPrice(
    item.merchandise.priceV2.currencyCode,
    Number(
      item?.sellingPlanAllocation
        ? item?.sellingPlanAllocation?.priceAdjustments[0]?.price?.amount
        : item.merchandise.priceV2.amount
    )
  );

  const onChangeQuantity = (value) => {
    handleQuantityChange(value);
  };

  // const debounce = (func) => {
  //   let timer;
  //   return function (...args) {
  //     const context = this;
  //     if (timer) clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       timer = null;
  //       func.apply(context, args);
  //     }, 1000);
  //   };
  // };

  const quantityFn = React.useCallback(debounce(onChangeQuantity), []);

  //   const subtotal = formatPrice(
  //     item.variant.priceV2.currencyCode,
  //     Number(item.variant.priceV2.amount) * quantity
  //   );

  const handleRemove = () => {
    removeLineItem(item.id);
  };

  const uli = debounce(
    (value) =>
      updateLineItem(
        item?.id,
        item?.merchandise?.id,
        value,
        item?.sellingPlanAllocation &&
          item?.sellingPlanAllocation?.sellingPlan?.id
      ),
    300
  );

  const debouncedUli = React.useCallback((value) => uli(value), []);

  const handleQuantityChange = (value) => {
    if (value !== "" && Number(value) < 1) {
      return;
    }
    setQuantity(value);
    if (Number(value) >= 1) {
      debouncedUli(value);
    }
  };

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1);
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1);
  }

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        crop: "contain",
        width: 69,
        height: 89,
      }),
    [variantImage.src]
  );
  return (
    <div className="flex w-full border-b border-black">
      <div className="w-[55%] md:w-[65%]">
        <div className="pt-[22px] pb-[27px] pl-4 sm:pl-8">
          <div className="flex flex-col sm:items-center sm:flex-row">
            {image && (
              <div>
                <GatsbyImage image={image} alt="pro" />
              </div>
            )}
            <div className="sm:pl-[15px]">
              <h2 className="text-[17px] leading-common">
                {item?.merchandise?.product?.title}{" "}
                {item.merchandise?.title !== "Default Title" &&
                  `- ${item?.merchandise?.title}`}
              </h2>
              <button
                className="flex items-center"
                onClick={handleRemove}
                disabled={loading}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-1"
                >
                  <path
                    d="M9 9h2v8H9V9zM12.999 9h2v8h-2V9z"
                    fill="currentColor"
                  ></path>
                  <path
                    stroke="currentColor"
                    strokeWidth="2"
                    d="M10 9v8"
                  ></path>
                  <path
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    d="M20 6H4"
                  ></path>
                  <path
                    stroke="currentColor"
                    strokeWidth="2"
                    d="M14 9v8M8 4.75C8 3.784 8.784 3 9.75 3h4.5c.966 0 1.75.784 1.75 1.75V6H8V4.75z"
                  ></path>
                  <path
                    d="M6 6h12v12a2 2 0 01-2 2H8a2 2 0 01-2-2V6z"
                    stroke="currentColor"
                    strokeWidth="2"
                  ></path>
                </svg>
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[20%] md:w-[16%] border-l border-black">
        <div className="flex items-center justify-center w-full h-full">
          <div className="flex flex-col items-center justify-center sm:flex-row ">
            <button className="h-8 w-7" onClick={doDecrement} disabled={loading}>
              <svg
                width="14"
                height="8"
                viewBox="0 0 8 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mx-auto"
              >
                <path d="M7.08397 0V1.15234H0V0H7.08397Z" fill="black" />
              </svg>
            </button>
            <input
              className="text-base sm:text-[20px] leading-common outline-none my-8 mx-2 sm:mx-4 w-10 text-center q-input"
              value={quantity}
              type="number"
              onChange={(e) => quantityFn(e.target.value)}
            />
            <button className="h-8 w-7" onClick={doIncrement} disabled={loading}>
              <svg
                width="15"
                height="14"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mx-auto"
              >
                <path
                  d="M6.71038 0V5.41523H12.1094V6.56511H6.71038V12H5.52841V6.56511H0.109375V5.41523H5.52841V0H6.71038Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="w-[25%] md:w-[19%] border-l border-black">
        <div className="flex items-center justify-center w-full h-full">
          <span className="text-base sm:text-[20px] leading-common">
            {price}
          </span>
        </div>
      </div>
    </div>
  );
}
