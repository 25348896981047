import React from "react";
import PortableText from "react-portable-text";

export default function ShippingPolicy({ onClose, content }) {
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-black/60">
      <div className="flex items-center justify-center h-full">
        <div className="relative w-full px-8 py-5 m-5 bg-white md:w-2/4 ">
          <div className="absolute right-5 top-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#000"
              class="bi bi-x-lg"
              viewBox="0 0 16 16"
              onClick={onClose}
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
            </svg>
          </div>
          <div className="h-[500px] overflow-y-auto">
            <PortableText
              content={content}
              className="px-4 mt-5 block-content max-w-none md:px-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
