import React from "react";
import DatePicker from "react-datepicker";
import { StoreContext } from "../../context/storeContext";
import { formatDate } from "../../utils/format-date";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/calender.css";

export default function DeliveryDate({
  selected,
  onChange,
  deliveryDates = [],
  aatodd = 0,
}) {
  const after6Days = new Date();
  after6Days.setDate(after6Days.getDate() + aatodd);
  const { updateCustomAttributtes } = React.useContext(StoreContext);
  const avaiableDates = (date) => {
    for (let i = 0; i < deliveryDates.length; i++) {
      const day = new Date(deliveryDates[i]);
      day.setMinutes(day.getMinutes() + day.getTimezoneOffset());

      if (day.toDateString() === date.toDateString()) {
        if (day.getTime() > after6Days.getTime()) {
          return true;
        }
      }
    }
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const onChangeHandler = (date) => {
    const customAttributes = {
      key: "DeliveryDate",
      value: formatDate(date),
    };
    updateCustomAttributtes(customAttributes);
    onChange(date);
  };

  return (
    <div className="mt-[19px]">
      <span className="font-primary block font-normal text-center text-[20px] text-black leading-[177.4%]">
        Select Your Targeted Delivery Date
      </span>
      <div className="mt-[14px]">
        <DatePicker
          selected={selected}
          onChange={(date) => onChangeHandler(date)}
          inline
          minDate={new Date()}
          filterDate={avaiableDates}
          formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
}
