import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import BeatLoader from "react-spinners/BeatLoader";
import Layout from "../components/layout";
import Heading from "../components/common/heading";
import DeliveryDate from "../components/checkout/DeliveryDate";
import NewsLetters from "../components/common/newsletter";
import LineItem from "../components/cart/lineItem";
import { StoreContext } from "../context/storeContext";
import ShippingPolicy from "../components/common/popup/ShippingPolicy";
import { formatPrice } from "../utils/format-price";
import { formatDate } from "../utils/format-date";
import Seo from "../components/common/SEO";

const CART_TYPE = {
  SUBSCRIPTION: "SUBSCRIPTION",
  NON_SUBCRIPTION: "NON_SUBCRIPTION",
  MIXTURE: "MIXTURE",
};

export default function Cart({
  data: { sanityDeliveryDatesPicker, sanityShippingPolicy },
}) {
  const { deliveryDates, aatodd } = sanityDeliveryDatesPicker;
  const [selectedDate, setSelectedDate] = useState(null);
  const [error, setError] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [shippingPopup, setShippingPopup] = useState(false);
  const [firstAvailableDate, setFirstAvailableDate] = useState("");
  const [cartType, setCartType] = useState("");
  const { checkout, loading, updateCustomAttributtes } =
    React.useContext(StoreContext);

  const moveToCheckout = () => {
    const overflowQty = checkout?.lines.edges.reduce((total, item) => {
      return total + parseInt(item.node.quantity);
    }, 0);

    if (overflowQty > 96) {
      alert(
        "Orders over 96 items are not accepted so you know the problem before you try to ship."
      );
      return;
    }
    if (cartType === CART_TYPE.SUBSCRIPTION) {
      const customAttributes = {
        key: "DeliveryDate",
        value: firstAvailableDate,
      };
      updateCustomAttributtes(customAttributes);
      window.location.replace(checkout.checkoutUrl);
      return;
    }
    if (selectedDate && !loading) {
      setError(false);
      window.location.replace(checkout.checkoutUrl);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (!checkout?.id || !checkout?.lines?.edges.length > 0) {
      window.location.replace("/");
    } else {
      setShowCart(true);
    }
    checkCartType();
  }, [checkout]);

  useEffect(() => {
    if (cartType === CART_TYPE.MIXTURE) {
      alert(
        "Mystery Mix subscription must be placed as a separate order.  Please remove the last item, complete and pay for your order and then create a new order for the desired product(s)."
      );
    }
  }, [cartType]);

  useEffect(() => {
    checkAvailableDate();
  }, []);

  const checkCartType = () => {
    // check types of products in cart onetime/subscription
    if (checkout?.lines?.edges.length > 0) {
      const subscriptionProducts = checkout?.lines?.edges.filter(
        ({ node }) => node?.sellingPlanAllocation
      );
      const nonSubscriptionProducts = checkout?.lines?.edges.filter(
        ({ node }) => node?.sellingPlanAllocation === null
      );
      if (
        subscriptionProducts.length > 0 &&
        nonSubscriptionProducts.length > 0
      ) {
        setCartType(CART_TYPE.MIXTURE);
      }
      if (
        subscriptionProducts.length > 0 &&
        nonSubscriptionProducts.length === 0
      ) {
        setCartType(CART_TYPE.SUBSCRIPTION);
      }
      if (
        subscriptionProducts.length === 0 &&
        nonSubscriptionProducts.length > 0
      ) {
        setCartType(CART_TYPE.NON_SUBCRIPTION);
      }
    }
  };

  const checkAvailableDate = () => {
    const sortedDeliveryDates = deliveryDates.sort(
      (a, b) => new Date(a) - new Date(b)
    );
    const nextMonthDate = new Date();

    nextMonthDate.setMinutes(
      nextMonthDate.getMinutes() + nextMonthDate.getTimezoneOffset()
    );
    nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);

    const filteredDeliveryDates = sortedDeliveryDates.filter((a) => {
      const dateOffset = new Date(a);
      dateOffset.setMinutes(
        dateOffset.getMinutes() + dateOffset.getTimezoneOffset()
      );
      return dateOffset.getMonth() === nextMonthDate.getMonth();
    });

    const day = new Date(filteredDeliveryDates[0]);
    day.setMinutes(day.getMinutes() + day.getTimezoneOffset());

    setFirstAvailableDate(formatDate(day));
  };

  const onClosePopup = () => {
    setShippingPopup(false);
  };

  if (!showCart) return <></>;
  return (
    <Layout>
      <Seo title="Cart" />
      <div className="pt-[82px]">
        <div className="mix-container">
          <div className="flex flex-col items-center justify-center px-2 md:px-0">
            <Heading title="CART" />
            {checkout?.lines.edges.length > 0 && (
              <>
                <div className="w-full mt-12 border-t border-l border-r border-black">
                  <div className="flex w-full border-b border-black">
                    <div className="w-[55%] md:w-[65%]">
                      <div className="pt-[10px] pb-3 pl-4 sm:pl-8">
                        <span className="text-[17px] font-medium leading-common">
                          Item
                        </span>
                      </div>
                    </div>
                    <div className="w-[20%] md:w-[16%] border-l border-black">
                      <div className="pt-[10px] pb-3 text-center">
                        <span className="text-[17px] font-medium leading-common">
                          Qty.
                        </span>
                      </div>
                    </div>
                    <div className="w-[25%] md:w-[19%] border-l border-black">
                      <div className="pt-[10px] pb-3 text-center">
                        <span className="text-[17px] font-medium leading-common">
                          Price
                        </span>
                      </div>
                    </div>
                  </div>
                  {checkout.lines.edges.map(({ node }, index) => (
                    <LineItem item={node} key={index} />
                  ))}
                </div>
                <div className="flex justify-end w-full mt-2">
                  <span className="text-[20px] leading-common font-medium mr-[22px]">
                    Items Price
                  </span>
                  <span className="text-[20px] leading-common">
                    {formatPrice(
                      checkout?.cost?.subtotalAmount?.currencyCode,
                      checkout?.cost?.subtotalAmount?.amount
                    )}
                  </span>
                </div>
                {cartType === CART_TYPE.NON_SUBCRIPTION && (
                  <div className="w-full mt-5 mb-4 border-b border-[#eee] pb-4">
                    <DeliveryDate
                      selected={selectedDate}
                      deliveryDates={deliveryDates}
                      onChange={setSelectedDate}
                      aatodd={aatodd}
                    />
                  </div>
                )}
                <div className="flex flex-col items-end w-full mt-5">
                  {error && (
                    <div className="mb-5 text-sm text-red-700">
                      Please Select Delivery Date First*
                    </div>
                  )}
                </div>
                <div className="flex items-end justify-between w-full">
                  <button
                    className="border-[1.5px] border-black tracking-[0.21em] pl-[69px] pr-[67px] pt-[15px] pb-[15px] text-[13px] font-medium leading-common hover:bg-black hover:text-white"
                    onClick={() => setShippingPopup(true)}
                    disabled={loading}
                  >
                    SHIPPING POLICY
                  </button>
                  {cartType !== CART_TYPE.MIXTURE && (
                    <button
                      className="border-[1.5px] border-black tracking-[0.21em] pl-[69px] pr-[67px] pt-[15px] pb-[15px] text-[13px] font-medium leading-common hover:bg-black hover:text-white min-w-[227px]"
                      onClick={moveToCheckout}
                      disabled={loading}
                    >
                      {loading ? (
                        <BeatLoader color="#000" loading={true} size={15} />
                      ) : (
                        "CHECKOUT"
                      )}
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className=" mt-[82px]">
          <NewsLetters small={true} />
        </div>
      </div>
      {shippingPopup && (
        <ShippingPolicy
          content={sanityShippingPolicy?._rawContent}
          onClose={onClosePopup}
        />
      )}
    </Layout>
  );
}

export const query = graphql`
  query {
    sanityDeliveryDatesPicker {
      deliveryDates
      aatodd
    }
    sanityShippingPolicy {
      _rawContent
    }
  }
`;
